import React, { useState } from "react";
import styled from "styled-components";
import wang from "../../../images/wang.png";
import wang2 from "../../../images/wang2.png";
import { EnContainer, EnPhoto } from "./style";

export const Wang = () => {
  const [wangStyle, setWangStyle] = useState(false);

  const onMouseEnterWang = () => {
    setWangStyle(true);
  };
  const onMouseLeaveWang = () => {
    setWangStyle(false);
  };

  return (
    <Container onMouseEnter={onMouseEnterWang} onMouseLeave={onMouseLeaveWang}>
      <WangImg src={wang2} />
      {wangStyle ? (
        <WangInfo>
          <WangInfoP1>王亮 Liang Wang</WangInfoP1>
          <WangInfoP2>
            <p>CFA</p>
            <p>工作经历：晤桥亚洲、新加坡国立大学</p>
            <p>学历：新加坡国立大学化学硕士、兰州大学学士</p>
          </WangInfoP2>
        </WangInfo>
      ) : (
        <WangTag>
          <p>王亮 Liang Wang</p>
        </WangTag>
      )}
    </Container>
  );
};

export const EnWang = () => {
  return (
    <EnContainer>
      <EnPhoto bg={wang} alt="" />
      <div className="info">
        <div className="list">
          <div className="title">Partner:</div>
          <div className="list-info">Wang Liang, CFA</div>
        </div>
        <div className="list">
          <div className="title">Experience:</div>
          <div className="list-info">
            Bridge5 Asia
            <br />
            NUS Research
          </div>
        </div>
        <div className="list">
          <div className="title">Education:</div>
          <div className="list-info">
            NUS MS
            <br />
            Lanzhou University BS
          </div>
        </div>
      </div>
    </EnContainer>
  );
};
const Container = styled.div`
  width: 249px;
  height: 249px;
  position: relative;
`;

const WangImg = styled.img`
  width: 249px;
  height: 249px;
  object-fit: contain;
`;
const WangInfo = styled.div`
  width: 249px;
  height: 249px;
  background-color: #2f3852;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 17px 0 17px;
`;
const WangInfoP1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 15px;
`;
const WangInfoP2 = styled.div`
  height: 187px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.35px;
  color: #ffffff;
  p {
    margin: 0;
  }
`;
const WangTag = styled.div`
  width: 249px;
  height: 50px;
  opacity: 0.75;
  background-color: #2f3852;
  position: absolute;
  bottom: 0;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
  }
`;
