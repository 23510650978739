import styled from "styled-components";
import React, { useState } from "react";
import { EnContainer, EnPhoto } from "./style";
import XIYANImg from "../../../images/img/xiyan.png";

export const Xiyan = () => {
  const [allanStyle, setAllanStyle] = useState(false);

  const onMouseEnterAllan = () => {
    setAllanStyle(true);
  };
  const onMouseLeaveAllan = () => {
    setAllanStyle(false);
  };

  return (
    <Container onMouseEnter={onMouseEnterAllan} onMouseLeave={onMouseLeaveAllan}>
      <AllanImg src={XIYANImg} />
      {allanStyle ? (
        <AllanInfo>
          <AllanInfoP1>唐希言 Tang Xiyan</AllanInfoP1>
          <AllanInfoP2>
            <p>工作经历：晤桥亚洲、 厦门日报新媒体中心</p>
            <p>学历：英国赫尔大学媒体与社会学学士、英国爱丁堡大学、数字媒体硕士</p>
          </AllanInfoP2>
        </AllanInfo>
      ) : (
        <AllanTag>
          <p>唐希言 Tang Xiyan</p>
        </AllanTag>
      )}
    </Container>
  );
};

export const EnXiyan = () => {
  return (
    <EnContainer>
      <EnPhoto bg={XIYANImg} alt="" />
      <div className="info">
        <div className="list">
          <div className="title">Partner:</div>
          <div className="list-info">Tang Xiyan</div>
        </div>
        <div className="list">
          <div className="title">Experience:</div>
          <div className="list-info">
            Bridge5 Asia
            <br />
            Xiamen Daily Digital Media Dept
          </div>
        </div>
        <div className="list">
          <div className="title">Education:</div>
          <div className="list-info">
            Edinburg University MS Digital Media
            <br />
            Hull University BA Media And
            <br />
            Sociology
          </div>
        </div>
      </div>
    </EnContainer>
  );
};
const Container = styled.div`
  width: 249px;
  height: 249px;
  position: relative;
`;

const AllanImg = styled.img`
  width: 249px;
  height: 249px;
`;
const AllanInfo = styled.div`
  width: 249px;
  height: 249px;
  background-color: #2f3852;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
`;
const AllanInfoP1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const AllanInfoP2 = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.35px;
  color: #ffffff;
  padding: 0 17px 0 17px;
  p {
    margin: 0;
  }
`;
const AllanTag = styled.div`
  width: 249px;
  height: 50px;
  opacity: 0.75;
  background-color: #2f3852;
  position: absolute;
  bottom: 0;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
  }
`;
