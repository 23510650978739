import allan from "../../../images/allan1.png";
import allan2 from "../../../images/allan2.png";
import styled from "styled-components";
import React, { useState } from "react";
import { EnContainer, EnPhoto } from "./style";

export const Allan = () => {
  const [allanStyle, setAllanStyle] = useState(false);
  const onMouseEnterAllan = () => {
    setAllanStyle(true);
  };
  const onMouseLeaveAllan = () => {
    setAllanStyle(false);
  };
  return (
    <Container onMouseEnter={onMouseEnterAllan} onMouseLeave={onMouseLeaveAllan}>
      <AllanImg src={allan2} />
      {allanStyle ? (
        <AllanInfo>
          <AllanInfoP1>吴晓军 Allan Wu</AllanInfoP1>
          <AllanInfoP2>
            <p>CFA、CPA</p>
            <p>工作经历：新加坡政府投资公司(GIC)、和记黄埔(HWPG)</p>
            <p>
              学历：麻省理工学院(MIT)房地产硕士 <br />
              德克萨斯大学奥斯丁分校会计硕士 <br />
              北京外交学院学士
            </p>
          </AllanInfoP2>
        </AllanInfo>
      ) : (
        <AllanTag>
          <p>吴晓军 Allan Wu</p>
        </AllanTag>
      )}
    </Container>
  );
};

export const EnAllan = () => {
  return (
    <EnContainer>
      <EnPhoto bg={allan} alt="" />
      <div className="info">
        <div className="list">
          <div className="title">Founder:</div>
          <div className="list-info">Allan Wu, CFA、CPA</div>
        </div>
        <div className="list">
          <div className="title">Experience:</div>
          <div className="list-info">
            GIC
            <br />
            HWPG
          </div>
        </div>
        <div className="list">
          <div className="title">Education:</div>
          <div className="list-info">
            MIT MSRED
            <br />
            UT Austin MPA
            <br />
            Foreignaffairs College, china
          </div>
        </div>
      </div>
    </EnContainer>
  );
};

const Container = styled.div`
  width: 249px;
  height: 249px;
  position: relative;
`;

const AllanImg = styled.img`
  width: 249px;
  height: 249px;
`;
const AllanInfo = styled.div`
  width: 249px;
  height: 249px;
  background-color: #2f3852;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
`;
const AllanInfoP1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const AllanInfoP2 = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: 0.35px;
  color: #ffffff;
  padding: 0 17px 0 17px;
  p {
    margin: 0;
  }
`;
const AllanTag = styled.div`
  width: 249px;
  height: 50px;
  opacity: 0.75;
  background-color: #2f3852;
  position: absolute;
  bottom: 0;
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
  }
`;
