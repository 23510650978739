import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { Flex, FlexCenter } from "../style/index";
import { graphql, StaticQuery } from "gatsby";
import { Footer } from "../components/Footer";
import { Team } from "../components/Team";
import { Service } from "../components/Serviceitems";
import { NavBar } from "../components/nav-bar";

export default () => {
  return (
    <Layout>
      <Service />
      <Team />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
