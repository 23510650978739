import React from "react";
import styled from "styled-components";
import { Allan, EnAllan } from "./Teammate/Allan";
import { Wang, EnWang } from "./Teammate/Wang";
import { Joe, EnJoe } from "./Teammate/Joe";
import { Eason, EnEason } from "./Teammate/Person1";
import { Sharon, EnSharon } from "./Teammate/Person2";
import { Xiyan, EnXiyan } from "./Teammate/Person3";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const Team = () => {
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <TeamContent>
        <TeamContentP1>{t("coremembers")}</TeamContentP1>
        {i18n.language === "zh" ? (
          <>
            <Title>Founder</Title>
            <Teammate style={{ marginTop: "10px" }}>
              <Allan />
              <Joe />
              <Xiyan />
            </Teammate>
            {/* <Teammate>
              <Eason />
              <Sharon />
              <Wang />
            </Teammate> */}
          </>
        ) : (
          <>
            <Title style={{ marginLeft: "41px" }}>Founder</Title>
            <Teammate style={{ marginTop: "10px", padding: "0 30px" }}>
              <EnAllan />
              {/* <EnEason /> */}
              <EnJoe />
            </Teammate>
            {/* <Teammate style={{ marginTop: "30px", padding: "0 30px" }}>
              <EnWang />
              <EnSharon />
            </Teammate> */}
            <Teammate style={{ marginTop: "30px", padding: "0 30px" }}>
              <EnXiyan />
              <div style={{ visibility: "hidden" }}>
                <EnSharon />
              </div>
            </Teammate>
          </>
        )}
      </TeamContent>
    </Container>
  );
};

const Title = styled.h6`
  width: 107px;
  height: 35px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.53px;
  color: #35383b;
  margin-left: 62px;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TeamContent = styled.section`
  width: 1107px;
  position: relative;
  margin-bottom: 118px;
`;
const TeamContentP1 = styled.p`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1.19px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  width: 100%;
  color: #2f3852;
  margin-top: 44px;
  margin-bottom: 48px;
`;
const Teammate = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
`;
